//import Excel from 'exceljs/dist/es5/exceljs.browser';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { Buffer } from 'buffer';

import { JOURNAL_TEMPLATE, XLSX_CONVER_API } from '../constants';
import { properFormat, formatedToNumber } from './math';

const getJournalTemplate = async () => {
  const f = Buffer.from(await (await fetch(JOURNAL_TEMPLATE)).arrayBuffer());
  const wb = new Excel.Workbook();
  await wb.xlsx.load(f);

  return wb;
}

const writeCompany = (ws, company) => {
  ws.getCell('B1').value = company.name;
  ws.getCell('B2').value = company.address;
  ws.getCell('B3').value = company.zip + ' ' + company.place;
}

const writeEmployeeData = (ws, employee) => {
  ws.getCell('E1').value = employee.name + ' ' + employee.surname;
  ws.getCell('E2').value = employee.address;
  ws.getCell('E3').value = employee.zip + ', ' + employee.place;

  ws.getCell('I1').value = employee.ahv_number;
  ws.getCell('L5').value = employee.iban;
  ws.getCell('L6').value = employee.bank_name;

  ws.getCell('G5').value = employee.birthdate;

  ws.getCell('P4').value = employee.county;
}

export const writeMonthlySalary = (ws, salary) => {
  const monthMapping = ['D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O'];

  const col = monthMapping[salary.month - 1];

  for (let row = 9; row <= 72; row++) {
    const code = ws.getCell(`A${row}`)?.value;
    if (!code) {
      continue;
    }

    ws.getCell(`${col}8`).value = 1;

    const item = salary.salary_monthly_items.find(el => el.journal_code == code);
    if (item) {
      if (item.type === 'p') {
        ws.getCell(`C${row}`).value = formatedToNumber(item.value / 100);
      }
      ws.getCell(`${col}${row}`).value = formatedToNumber(item.total);
    }
  }
}

export const exportJournal = async (company, employee, salaries) => {
  const wb = await getJournalTemplate();
  const ws = wb.getWorksheet('1');

  ws.getCell('P6').value = salaries[0]?.year;

  writeCompany(ws, company);
  writeEmployeeData(ws, employee);

  salaries.forEach(el => {
    writeMonthlySalary(ws, el);
  });

  wb.removeWorksheet('Erläuterungen');
  wb.removeWorksheet('Datenblatt');
  wb.removeWorksheet('AHV-Abrechnung');

  const buf = await wb.xlsx.writeBuffer();

  const data = new FormData();
  data.append('file', new Blob([buf]));

  const res = await fetch(XLSX_CONVER_API, {
    method: 'POST',
    body: data,
  }).then(r => r.blob());

  saveAs(res, 'journal.pdf');
}
