import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  MailLock as MailLockIcon,
} from '@mui/icons-material';
import dateFormat from 'dateformat';

import { withStore } from '../store';
import { db } from '../db';
import { translate } from '../utils/translate';
import Layout from '../hoc/Layout';
import CCard from '../hoc/Card';
import GenericTable from '../components/GenericTable';
import ConfirmDialog from '../components/ConfirmDialog';
import Translate from '../components/Translate';

const columns = [
  {
    id: 'actions',
    title: '',
  },
  {
    id: 'name',
    title: translate('Ime'),
  },
  {
    id: 'surname',
    title: translate('Prezime'),
  },
  {
    id: 'birthdate',
    title: translate('Datum rodjenja'),
  },
  {
    id: 'ahv_number',
    title: translate('AHV'),
  },
]


const Employees = (props) => {
  const navigate = useNavigate();

  const [removeId, setRemoveId] = useState(null);

  const [values, setValues] = useState([]);

  const [departmentId, setDepartmentId] = useState('all');
  const [departments, setDepartments] = useState([]);

  const [showAll, setShowAll] = useState(false)

  const fetchDepartments = async () => {
    const res = await db.from('departments')
      .select();

    if (res.error) {
      console.log(res.error);
      return;
    }

    setDepartments(res.data);
  }

  const fetchData = async () => {
    let res
    if (showAll) {
      res = await db.from('employees')
        .select()
        .eq('users_id', props.session.user.id)
        .order('surname')
        .order('name');
    } else {
      res = await db.from('employees')
        .select()
        .eq('users_id', props.session.user.id)
        .is('employment_to', null)
        .order('surname')
        .order('name');
    }

    if (res.error) {
      console.log(res.error);
      return;
    }

    setValues(res.data.map(el => {
      return {
        ...el,
        birthdate: dateFormat(el.birthdate, 'dd.mm.yyyy.'),
      }
    }));
  }

  const deleteData = async (id) => {
    const res = await db.from('employees')
      .delete()
      .eq('id', id);

    if (res.error) {
      console.log(res.error);
    }

    setValues(values.filter(el => el.id !== id));
  }

  useEffect(() => {
    fetchData()
      .catch(err => console.log(err));

    fetchDepartments()
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    fetchData()
      .catch(err => console.log(err));
  }, [showAll])

  const onEditHandler = (id) => {
    navigate('/employees/' + id);
  }

  const onDeleteHandler = (id) => {
    deleteData(removeId)
      .catch(err => console.log(err));
    setRemoveId(null);
  }

  let removeDialog = null;
  if (removeId) {
    removeDialog = (
      <ConfirmDialog
        title={translate('Brisanje radnika')}
        text={translate('Da li ste sigurni da želite obrisati radnika')}
        open={!!removeId}
        onConfirm={onDeleteHandler}
        onCancel={() => setRemoveId(null)}
        payload={removeId}
        danger
      />
    );
  }

  const departmentOptions = departments.map(el => {
    return (
      <MenuItem value={el.id}>{el.department}</MenuItem>
    );
  });

  const data = values
    .filter(el => departmentId === 'all' || el.departments_id === departmentId)
    .map(el => {
      const res = {
        ...el,
        actions: (
          <>
            <IconButton onClick={() => onEditHandler(el.id)}>
              <EditIcon />
            </IconButton>
            <IconButton color="error" onClick={() => setRemoveId(el.id)}>
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={() => console.log('TODO: Send email')}>
              <MailLockIcon />
            </IconButton>
          </>
        ),
      };

      return res;
    });

  return (
    <Layout title="Radnici">
      <CCard title=" " back={() => navigate(-1)}>
        <FormControl fullWidth>
          <InputLabel id="departments-lbl"><Translate>Odeljenje</Translate></InputLabel>
          <Select
            labelId="departments-lbl"
            id="departments"
            label={translate('Odeljenje')}
            value={departmentId}
            onChange={(e) => setDepartmentId(e.target.value)}>
            <MenuItem value="all"><Translate>Sva odeljenja</Translate></MenuItem>
            {departmentOptions}
          </Select>
        </FormControl>
        <br />
        <br />
        <div>
          <Button variant="contained" onClick={() => onEditHandler('new')}><Translate>Novi radnik</Translate></Button>
          &nbsp;
          &nbsp;
          &nbsp;
          <FormControlLabel
            control={<Switch checked={showAll} onChange={e => setShowAll(e.target.checked)} />}
            label={translate('Prikaži sve radnike')} />
        </div>
        <br />
        <br />
        <GenericTable
          columns={columns}
          data={data} />
      </CCard>

      {removeDialog}
    </Layout >
  );
}

export default withStore(['nav'])(Employees);
