import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import dateFormat from 'dateformat';

import { properRound, properFormat, formatedToNumber } from '../utils/math';
import { translate } from '../utils/translate';
import { LOGO_BASE_URL } from '../constants';

const months = [
  'Januar',
  'Februar',
  'Mart',
  'April',
  'Maj',
  'Jun',
  'Jul',
  'Avgust',
  'Septembar',
  'Oktobar',
  'Novembar',
  'Decembar',
]

export const createMonthlyPdf = (data) => {
  const {
    salary,
    taxes,
    other,
    salaryTotal,
    taxesTotal,
    otherTotal,
    company,
    employee,
    month,
    year,
    salary_description,
    date,
  } = data;

  const salaryComp = salary.map(el => {
    return (
      <View style={styles.tableRow}>
        <View style={styles.tableCell}>
          <Text>{el.description ? el.description : ''} {el.has_description && el.description_add ? ' - ' + el.description_add : ''}</Text>
        </View>
        <View style={{ ...styles.tableCell, ...styles.right }}>
          <Text>{formatedToNumber(el.value) ? properFormat(formatedToNumber(el.value), el.type === 'p' ? 3 : 2) : ''}{el.value && el.type === 'p' ? '%' : ''}{el.value && el.type === 'h' ? 'h' : ''}</Text>
        </View>
        <View style={{ ...styles.tableCell, ...styles.right }}>
          <Text>{formatedToNumber(el.base) ? properFormat(formatedToNumber(el.base)) : ''}</Text>
        </View>
        <View style={{ ...styles.tableCell, ...styles.right }}>
          <Text>{formatedToNumber(el.total) ? properFormat(formatedToNumber(el.total)) : ''}</Text>
        </View>
        <View style={{ ...styles.tableCell, ...styles.right }}>
        </View>
        );
      </View>
    );
  });

  const taxesComp = taxes.map(el => {
    return (
      <View style={styles.tableRow}>
        <View style={styles.tableCell}>
          <Text>{el.description ? el.description : ''} {el.has_description && el.description_add ? ' - ' + el.description_add : ''}</Text>
        </View>
        <View style={{ ...styles.tableCell, ...styles.right }}>
          <Text>{formatedToNumber(el.value) ? properFormat(formatedToNumber(el.value), el.type === 'p' ? 3 : 2) : ''}{el.value && el.type === 'p' ? '%' : ''}</Text>
        </View>
        <View style={{ ...styles.tableCell, ...styles.right }}>
          <Text>{formatedToNumber(el.base) ? properFormat(formatedToNumber(el.base)) : ''}</Text>
        </View>
        <View style={{ ...styles.tableCell, ...styles.right }}>
          <Text>{formatedToNumber(el.total) ? properFormat(formatedToNumber(el.total)) : ''}</Text>
        </View>
        <View style={{ ...styles.tableCell, ...styles.right }}>
        </View>
      </View>
    );
  });

  const otherComp = other.map(el => {
    return (
      <View style={styles.tableRow}>
        <View style={styles.tableCell}>
          <Text>{el.description ? el.description : ''} {el.has_description && el.description_add ? ' - ' + el.description_add : ''}</Text>
        </View>
        <View style={{ ...styles.tableCell, ...styles.right }}>
          <Text>{formatedToNumber(el.value) ? properFormat(formatedToNumber(el.value), el.type === 'p' ? 3 : 2) : ''}{el.value && el.type === 'p' ? '%' : ''}</Text>
        </View>
        <View style={{ ...styles.tableCell, ...styles.right }}>
          <Text>{formatedToNumber(el.base) ? properFormat(formatedToNumber(el.base)) : ''}</Text>
        </View>
        <View style={{ ...styles.tableCell, ...styles.right }}>
        </View>
        <View style={{ ...styles.tableCell, ...styles.right }}>
          <Text>{formatedToNumber(el.total) ? properFormat(formatedToNumber(el.total)) : ''}</Text>
        </View>
      </View>
    );
  });

  const MonthlyPdf = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.company}>
            <Text>{company.name}</Text>
            <Text>{company.address}</Text>
            <Text>{company.place}</Text>
          </View>
          <View style={styles.logo}>
            <Image src={`${LOGO_BASE_URL}/${company.logo}`} style={{ maxWidth: 75, maxHeight: 75 }} />
          </View>
        </View>

        <View style={styles.header}>
          <View style={styles.placeholder}>
          </View>
          <View style={styles.placeholder}>
          </View>
        </View>

        <View style={{ ...styles.header, marginTop: '20mm' }}>
          <View style={styles.data}>
          </View>
          <View style={styles.employee}>
            <Text> </Text>
            <Text> </Text>
            <Text style={styles.bold}>{employee.name} {employee.surname}</Text>
            <Text>{employee.address}</Text>
            <Text>{employee.zip} {employee.place}</Text>
          </View>
        </View>

        <View style={styles.header}>
          <View style={styles.data}>
            <View style={styles.dataValue}>
              <Text> </Text>
              <Text> </Text>
              <Text> </Text>
              <Text>{translate('Mesec:')}</Text>
              <Text> </Text>
              <Text>{translate('AHV:')}</Text>
              <Text>{translate('Zanimanje:')}</Text>
              <Text>{translate('Pensum:')}</Text>
              <Text>{translate('Datum:')}</Text>
            </View>

            <View style={styles.dataValue}>
              <Text> </Text>
              <Text> </Text>
              <Text> </Text>
              <Text>{translate(months[month - 1])}</Text>
              <Text> </Text>
              <Text>{employee.ahv_number} </Text>
              <Text>{employee.vocation} </Text>
              <Text>100.00% </Text>
              <Text>{dateFormat(date, 'dd.mm.yyyy.')}</Text>
            </View>
          </View>
          <View style={styles.data}>
          </View>
        </View>

        <View style={styles.section}>
          <View style={styles.tableContainer}>
            <View style={styles.tableRowTitle}>
              <View style={styles.tableCell}>
                <Text>{translate('Opis')}</Text>
              </View>
              <View style={{ ...styles.tableCell, ...styles.right }}>
                <Text>{translate('Vrednost')}</Text>
              </View>
              <View style={{ ...styles.tableCell, ...styles.right }}>
                <Text>{translate('Osnovica')}</Text>
              </View>
              <View style={{ ...styles.tableCell, ...styles.right }}>
                <Text>CHF</Text>
              </View>
              <View style={{ ...styles.tableCell, ...styles.right }}>
                <Text>{translate('Ukupno')}</Text>
              </View>
            </View>

            {salaryComp}

            <View style={styles.tableRowTotal}>
              <View style={styles.tableCell}>
                <Text style={styles.bold}>{translate('Ukupno')}</Text>
              </View>
              <View style={{ ...styles.tableCell, ...styles.right }}>
              </View>
              <View style={{ ...styles.tableCell, ...styles.right }}>
              </View>
              <View style={{ ...styles.tableCell, ...styles.right }}>
              </View>
              <View style={{ ...styles.tableCell, ...styles.right }}>
                <Text style={styles.bold}>{properFormat(formatedToNumber(salaryTotal))}</Text>
              </View>
            </View>

            <View style={styles.tableRowSpacing}>
            </View>

            {taxesComp}

            <View style={styles.tableRowTotal}>
              <View style={styles.tableCell}>
                <Text style={styles.bold}>{translate('Ukupno')}</Text>
              </View>
              <View style={{ ...styles.tableCell, ...styles.right }}>
              </View>
              <View style={{ ...styles.tableCell, ...styles.right }}>
              </View>
              <View style={{ ...styles.tableCell, ...styles.right }}>
              </View>
              <View style={{ ...styles.tableCell, ...styles.right }}>
                <Text style={styles.bold}>-{properFormat(formatedToNumber(taxesTotal))}</Text>
              </View>
            </View>

            <View style={styles.tableRowSpacing}>
            </View>

            {otherComp}

            <View style={styles.tableRowTotal}>
              <View style={styles.tableCell}>
                <Text style={styles.bold}>{translate('Ukupno')}</Text>
              </View>
              <View style={{ ...styles.tableCell, ...styles.right }}>
              </View>
              <View style={{ ...styles.tableCell, ...styles.right }}>
              </View>
              <View style={{ ...styles.tableCell, ...styles.right }}>
              </View>
              <View style={{ ...styles.tableCell, ...styles.right }}>
                <Text style={styles.bold}>{properFormat(formatedToNumber(otherTotal))}</Text>
              </View>
            </View>

            <View style={styles.tableRowSpacing}>
            </View>

            <View style={styles.tableRowTitle}>
              <View style={styles.tableCell}>
                <Text style={styles.title}>{translate('Za isplatu:')}</Text>
              </View>
              <View style={{ ...styles.tableCell, ...styles.right }}>
                <Text style={styles.title}>{properFormat(formatedToNumber(salaryTotal) - formatedToNumber(taxesTotal) + formatedToNumber(otherTotal))}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.footer}>
          <View style={{ ...styles.data, marginRight: '55mm' }}>
            <View style={styles.dataValue}>
              <Text style={styles.bold}>{translate('IBAN:')}</Text>
              <Text style={styles.bold}>{translate('Banka:')}</Text>
            </View>

            <View style={{ ...styles.dataValue, textAlign: 'right' }}>
              <Text>{employee.iban} </Text>
              <Text>{employee.bank_name} </Text>
            </View>
          </View>
        </View>


        <View style={styles.footer}>
          <View style={{ ...styles.data, marginRight: '55mm' }}>
            <View style={styles.dataValue}>
              {salary_description.split('\n').map(el => <Text>{el}</Text>)}
            </View>
          </View>
        </View>
      </Page>
    </Document >
  );

  return MonthlyPdf;
}


const styles = StyleSheet.create({
  page: {
    flexDirection: 'columns',
    fontFamily: 'Helvetica',
    fontSize: 9,
  },

  header: {
    flexDirection: 'row',
  },

  footer: {
    flexDirection: 'row',
    width: '100%',
    marginTop: '10mm',
  },

  company: {
    flexDirection: 'column',
    width: '100%',
    marginLeft: '25mm',
    marginTop: '10mm',
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
  },

  logo: {
    flexDirection: 'column',
    width: '100%',
    marginTop: '10mm',
    marginRight: '25mm',
    alignItems: "center",
  },

  employee: {
    flexDirection: 'column',
    width: '100%',
    marginLeft: '25mm',
  },

  data: {
    flexDirection: 'row',
    width: '100%',
    marginLeft: '25mm',
  },
  dataValue: {
    flexDirection: 'column',
    width: '100%',
  },

  placeholder: {
    flexDirection: 'column',
    width: '100%',
  },

  section: {
    flexDirection: 'columns',
    marginLeft: '25mm',
    marginRight: '57mm',
    marginTop: '5mm',
  },

  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 1,
    marginBottom: 1,
  },

  tableRowTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
    borderBottom: 1,
    borderBottomColor: 'black',
    borderTop: 1,
    borderTopColor: 'black',
    backgroundColor: '#ccc',

    marginTop: 2,
    marginBottom: 2,
    paddingTop: 2,
    paddingBottom: 2,
  },

  tableRowTotal: {
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 'bold',
    borderTop: 1,
    borderTopColor: 'black',

    marginTop: 2,
    marginBottom: 2,
    paddingTop: 2,
    paddingBottom: 2,
  },

  tableRowSpacing: {
    margin: 5,
  },

  tableCell: {
    width: '100%',
  },

  right: {
    textAlign: 'right',
    alignItems: 'flex-end',
    width: '50%',
  },

  bold: {
    fontWeight: 'bold',
    fontFamily: 'Helvetica-Bold',
  },

  title: {
    fontWeight: 'bold',
    fontSize: 11,
  },
});
